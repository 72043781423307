
import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Widget, addResponseMessage } from 'react-chat-widget-2';
import "react-chat-widget-2/lib/styles.css";

function App() {
  const ws = useRef(null);
  const hash = useRef(null);
  const chatId = useRef(null);
  const [isNewMessage, setNewMessage] = useState(false);

  let isFirst = true;

  useEffect(() => {
    if(!ws.current || ws.current.readyState === 3) ws.current = new WebSocket("wss://chattrbyte.ru:27800/");

    gettingData();

    //Изменить время, иначе прилетает в формате AM/PM
    let timestamps = document.querySelectorAll(".rcw-timestamp");
    let date = new Date();
    let hours = date.getHours();
    for(let timestamp of timestamps) {
      let timestampSplitted = timestamp.textContent.split(":");
      if(hours >= 12) {
        timestamp.textContent = hours + ":" + timestampSplitted[1];
      }
    }
    
    if(!isFirst) {
      return () => ws.current.close();
    }
  }, [ws, isFirst, isNewMessage]);

  const gettingData = useCallback(() => {
    if (!ws.current) return;
    ws.current.onmessage = e => { 
      const message = JSON.parse(e.data);

      if(message["type"] === "reply") {
        addResponseMessage(message["text"]);
      } else {
        hash.current = message["hash"];
        chatId.current = message["chat_id"];
      }

      setNewMessage(!isNewMessage);
    };
  }, [isNewMessage]);

  const handleNewUserMessage = (newMessage) => {
    let messages = new Map();

    messages.set('text', newMessage);
    messages.set('hash', hash.current);
    messages.set('chat_id', chatId.current);
    messages.set('type', 'request');
    messages.set('is_first', 'false');

    if(isFirst) {
      isFirst = false;
      messages.set('is_first', 'true');
    }

    ws.current.send(JSON.stringify(Object.fromEntries(messages)));

    setNewMessage(!isNewMessage);
  };

  return (
    <div className="pixora-chat">
      {<Widget
        title = "Все для стройки ПРОТЭК"
        senderPlaceHolder = "Введите свое сообщение и нажмите Enter"
        subtitle = "Онлайн"
        handleNewUserMessage={handleNewUserMessage}
      />}
    </div>
  );
}

export default App;